<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <h2 class="c-grey-900 mB-20" style="display: inline-block">
          {{lesson}}
        </h2>
        <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button class="btn btn-sm btn-success" @click="addQuestion">
          <i class="ti-plus mL-5" style="color: #fff"></i>إضافة سؤال
        </button>
        <div id="dataTable_wrapper" class="dataTables_wrapper">
          <form @submit="checkForm">
            <div class="form-group row pB-30 pT-30">
              <div class="col-sm-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="questionId"
                  placeholder="رقم السؤال"
                />
              </div>
              <div class="col-sm-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="entryId"
                  placeholder="رقم المدخل"
                />
              </div>
              <div class="col-sm-3">
                <input
                  type="date"
                  class="form-control"
                  v-model="date"
                  placeholder="تاريخ السؤال"
                />
              </div>
              <div class="col-sm-2">
                <button
                  type="submit"
                  class="btn btn-primary mL-10"
                  :disabled="loading"
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div>
          </form>
          <table
            id="dataTable"
            class="table table-bordered dataTable table-hover"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  السؤال
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 الإيجابات
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 إسم المدخل
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 تاريخ المدخل
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  تعديل
                </th>

                
              </tr>
            </thead>
            <tbody v-show="!loading" v-for="item in questions" :key="item.id">
              <tr role="row" class="odd">
                <td class="sorting_1">{{ item.id }}</td>
                <td v-if="checkForDollarSigns(item.text.body)">
                  <span v-for="(text, index) in processedText(item.text.body)" :key="index">
  <vue-mathjax
    v-if="!text.isText"
    style="display: inline;"
    :formula="text.text"
    safe=""
    id="math-formula"
    :options="customOptions"
  ></vue-mathjax>
  <span v-else>{{ text.text }}</span>
  <span v-if="index < processedText(item.text.body).length - 1">&nbsp;</span>
</span>

                  
                </td>
                <td v-else>
                  {{ item.text.body }}
                </td>
                <td>
                    <div v-for="answer in item.answers" :key="answer.id">
                        <div v-if="answer.is_true == 1" class="alert alert-success" role="alert">
                          <vue-mathjax v-if="checkForDollarSigns(answer.answer)" style="display: inline;" :formula="answer.answer" safe="" id="math-formula" :options="customOptions"></vue-mathjax>
                        <div v-else>{{ answer.answer }}</div>
                        </div>
                        <div v-else class="alert alert-secondary" role="alert">
                          <vue-mathjax v-if="checkForDollarSigns(answer.answer)" style="display: inline;" :formula="answer.answer" safe="" id="math-formula" :options="customOptions"></vue-mathjax>
                          <div v-else>{{ answer.answer }}</div>
                        </div>
                    </div>
                </td>
                <td>
                    {{item.entry.name}}
                </td>
                <td>
                    {{item.date}}
                </td>
                <td v-if="item.questions_count == 0" class="disabled">
                  لا يوجد أي سؤال 
                </td>
                <td>
                  <a href="javascript:"
                  @click="editQuestion(item)"
                    ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                      ><i class="ti-pencil"></i></span
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <loading v-show="loading" />
          <p v-show="!questions.length" style="text-align: center">
            {{ $t("noData") }}
          </p>
          <div style="text-align:center;margin-top:40px">
          <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 2 " :per-page="10" @paginate="getQuestions"/>
          </div>
        </div>
      </div>
    </div>

    <div class="modal popup" id="math" tabindex="-1" role="dialog" data-backdrop="static" >
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">إنشاء معادلة</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <formula />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="copy">نسخ</button>
        <button type="button" class="btn btn-primary" @click="arabicCopy">نسخ بالعربي</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">إلغاء</button>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import { VueMathjax } from 'vue-mathjax'
export default {
  components: {
    'vue-mathjax': VueMathjax
  },
    props:{
    id: {
      type: String,
      required: true
    },
    lesson: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      customOptions: {
        displayAlign: 'right',
      },
      loading: true,
      entryId: "",
      questionId: "",
      date: "",
      questions: [],
      pagination: {},
      page:1,
    };
  },
  created() {
    this.getQuestions();
  },
  methods: {
    checkForDollarSigns(text) {
      return text.includes('$$');
    },
    processedText(text) {
    const delimiter = '\\$\\$'; // RegExp delimiter for $$ in JavaScript
    const regExp = new RegExp(delimiter, 'g');
    const parts = text.split(regExp);

    const result = [];

    // Check if the first part starts with $$

    parts.forEach(part => {
        const trimmedPart = part.trim();
        if (trimmedPart.length > 0) {
            if (isText) {
                result.push({
                    text: trimmedPart,
                    isText: true
                });
            } else {
                result.push({
                    text: `$$${trimmedPart}$$`, // Add $$ back to the text
                    isText: false
                });
            }
        }
        // Flip isText status
    });

    console.log(result);

    return result;
},
    convertCdotToTimes(inputString) {
    // Use regular expression to replace all occurrences of "\cdot" with "\times"
    var outputString = inputString.replace(/\\cdot/g, "\\times");
    return outputString;
},
    convertToArabicNumbers(inputString) {
      const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      // Replace each digit in the string with its Arabic numeral counterpart
      return this.convertToArabicLetters(this.convertCdotToTimes(inputString.replace(/\d/g, digit => arabicNumerals[digit])));
    },
     convertToArabicLetters(formula) {
    // Define the Arabic letters for x, y, and z
    const arabicLetters = {
        'x': 'س',
        'y': 'ص',
        'z': 'ع'
        // Add more letters if needed
    };

    // Replace occurrences of x, y, and z with Arabic letters
    for (let letter in arabicLetters) {
        formula = formula.replace(new RegExp(letter, 'g'), arabicLetters[letter]);
    }
    return formula;
},
    copy() {
       // Create a temporary input element
      const input = document.getElementById('formula');

      input.value = this.convertCdotToTimes(input.value);

      // Select the text in the input
      input.select();

      // Execute the copy command
      document.execCommand('copy');

      $('#math').modal('hide');

    },
    arabicCopy() {
       // Create a temporary input element
      const input = document.getElementById('formula');
      input.value = this.convertToArabicNumbers(input.value);

      // Select the text in the input
      input.select();

      // Execute the copy command
      document.execCommand('copy');

      $('#math').modal('hide');

    },
    checkForm: function (e) {
      e.preventDefault();
      this.getQuestions();
    },
    getQuestions: function () {
      this.loading = true;
      this.$http
        .get("https://yalla-emtihan.com/backend/questions/getQuestions", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            question_id: this.questionId,
            entry_id: this.entryId,
            lesson_id: this.id,
            date: this.date,
            page: this.page
          },
        })
        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.questions = data.body.data;
                this.pagination = data.body;
                console.log(this.pagination);
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          }
        );
    },
    addQuestion: function () {
      const panel1Handle = this.$showPanel({
        component: () => import("../../lessons/questions/AddQuestion.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          reload:this.getQuestions,
          lessonId: this.id
        },
      });
    },
    editQuestion(question) {
      const panel1Handle = this.$showPanel({
        component: () => import("../../lessons/questions/EditQuestion.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          question: question,
          reload:this.getQuestions
        },
      });

      panel1Handle.promise.then((result) => {});
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning: function (message) {
      this.$toast.warning(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function (message) {
      this.$toast.success(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style>
.modal-backdrop {
  z-index: 100; /* Adjust the z-index value as needed */
}
.popup {
  z-index: 101;
}
#math-formula .MathJax_Display {
    display: inline !important;
    direction: rtl !important
}
#math-formula .MathJax_Preview {
    display: inline !important;
}
</style>